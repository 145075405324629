<template>
  <div>
    <el-upload
            ref="upload"
            class="avatar-uploader"
            :action="actionLocation"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :accept="accept"
            :show-file-list="false"
            :on-error="handleError"
            :headers="{ 'Authorization': token }">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </div>
</template>

<script>
export default {
  name: "UpdateImg",
  data() {
    return {
      actionLocation: "/web/common/uploadAvatar",
      imageUrl: "",
      token: ""
    };
  },
  mounted() {
    this.token = localStorage.getItem("token");
  },
  props: {
    selectImg:{
      type:String
    },
    accept: {
      type: String,
      default: "image/jpg,image/jpeg,image/png,image/gif",
    },
  },
  watch:{
    selectImg(val){
      this.imageUrl = val
    }
  },
  methods: {
    handleAvatarSuccess(file) {
      this.imageUrl = file.data.path;
      this.$message.success("图片上传成功");
      this.$emit("update:selectImg",file.data.path)
    },
    handleError() {
      this.$message.error("图片上传失败!!");
    },

    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传图片格式错误!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  background-color: #e8e8e8;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
  background-color: #fff;
}
.avatar-uploader-icon {
  font-size: 24px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.img {
  width: 100px;
  height: 100px;
}
</style>
